var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.miniCartVisible,
          expression: "miniCartVisible"
        }
      ],
      staticClass: "mini-cart"
    },
    [
      _c("div", { staticClass: "mini-cart__container" }, [
        _c("div", { staticClass: "mini-cart__title" }, [
          _c("span", {
            staticStyle: { width: "20px", height: "20px" },
            attrs: { "data-icon": "cart2" }
          }),
          _vm._v("\n            Koszyk "),
          _c("strong", [_vm._v("(" + _vm._s(_vm.miniCart.count || "0") + ")")]),
          _vm._v(":\n        ")
        ]),
        _vm._v(" "),
        _vm.miniCart.products
          ? _c(
              "ul",
              { staticClass: "mini-cart__list" },
              _vm._l(_vm.miniCart.products, function(product) {
                return _c(
                  "li",
                  {
                    key: product.id,
                    staticClass: "mini-cart__item",
                    class: {
                      "is-unavailable": product.available === "0",
                      "mini-cart__item--inactive":
                        product.customerActive === "0"
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "mini-cart__item-container",
                        attrs: { href: "" + _vm.BASE_URL + product.url }
                      },
                      [
                        _c("img", {
                          staticClass: "mini-cart__item-img",
                          attrs: {
                            src:
                              product.imgUrl === "rx"
                                ? _vm.getSkinUrl(
                                    "images/catalog/product/recipe/recepta2.png"
                                  )
                                : product.imgUrl,
                            alt: product.name ? product.name : "",
                            width: "50",
                            height: "50"
                          }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "mini-cart__item-name" }, [
                          _vm._v(_vm._s(product.name))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "mini-cart__item-qty" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(product.qty) +
                          " szt.\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mini-cart__item-price" }, [
                      _c(
                        "a",
                        {
                          staticClass: "mini-cart__item-remove",
                          attrs: { href: product.deleteUrl }
                        },
                        [
                          _c("span", {
                            staticStyle: { width: "20px", height: "20px" },
                            attrs: { "data-icon": "close" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(product.price))])
                    ]),
                    _vm._v(" "),
                    product.available === "0"
                      ? _c(
                          "span",
                          { staticClass: "mini-cart__item-unavailable_msg" },
                          [
                            _vm._v(
                              "\n                    Wybrana liczba sztuk produktu jest niedostępna\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.miniCart.count
          ? _c("p", { staticClass: "mini-cart__empty" }, [
              _vm._v(
                "\n            W Twoim koszyku nie ma jeszcze produktów\n        "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.miniCart.count
          ? _c("div", { staticClass: "mini-cart__totals" }, [
              _vm._v("\n            Razem: "),
              _c("span", [_vm._v(_vm._s(_vm.miniCart.total))])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.miniCart.count
          ? _c("div", { staticClass: "mini-cart__actions" }, [
              _c(
                "button",
                {
                  staticClass: "button btn--rect btn--bg",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.redirectToCart.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("span", {
                    staticStyle: { width: "33px", height: "32px" },
                    attrs: { "data-icon": "cart2white" }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Złóż zamówienie")])
                ]
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }