<template>
    <div v-show="miniCartVisible" class="mini-cart">
        <div class="mini-cart__container">
            <div class="mini-cart__title">
                <span data-icon="cart2" style="width: 20px; height: 20px" />
                Koszyk <strong>({{ miniCart.count || '0' }})</strong>:
            </div>
            <ul v-if="miniCart.products" class="mini-cart__list">
                <li
                    v-for="product in miniCart.products"
                    :key="product.id"
                    class="mini-cart__item"
                    :class="{
                        'is-unavailable': product.available === '0',
                        'mini-cart__item--inactive':
                            product.customerActive === '0'
                    }"
                >
                    <a
                        :href="`${BASE_URL}${product.url}`"
                        class="mini-cart__item-container"
                    >
                        <img
                            :src="
                                product.imgUrl === 'rx'
                                    ? getSkinUrl(
                                          'images/catalog/product/recipe/recepta2.png'
                                      )
                                    : product.imgUrl
                            "
                            :alt="product.name ? product.name : ''"
                            class="mini-cart__item-img"
                            width="50"
                            height="50"
                        />
                        <p class="mini-cart__item-name">{{ product.name }}</p>
                    </a>
                    <span class="mini-cart__item-qty">
                        {{ product.qty }} szt.
                    </span>
                    <div class="mini-cart__item-price">
                        <a
                            :href="product.deleteUrl"
                            class="mini-cart__item-remove"
                        >
                            <span
                                data-icon="close"
                                style="width: 20px; height: 20px"
                            />
                        </a>
                        <span>{{ product.price }}</span>
                    </div>
                    <span
                        v-if="product.available === '0'"
                        class="mini-cart__item-unavailable_msg"
                    >
                        Wybrana liczba sztuk produktu jest niedostępna
                    </span>
                </li>
            </ul>
            <p v-if="!miniCart.count" class="mini-cart__empty">
                W Twoim koszyku nie ma jeszcze produktów
            </p>
            <div v-if="miniCart.count" class="mini-cart__totals">
                Razem: <span>{{ miniCart.total }}</span>
            </div>
            <div v-if="miniCart.count" class="mini-cart__actions">
                <button
                    class="button btn--rect btn--bg"
                    @click.prevent="redirectToCart"
                >
                    <span
                        data-icon="cart2white"
                        style="width: 33px; height: 32px"
                    />
                    <span>Złóż zamówienie</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { EventBus, CART_UPDATED } from '../utils/event-bus'
import icons from '@js/app/icons.js'

export default {
    scopeName: 'minicart',
    data() {
        return {
            miniCartCounter: null,
            miniCartIcon: null,
            miniCartVisible: false,
            miniCart: {}
        }
    },
    watch: {
        miniCartVisible(isVisible) {
            if (isVisible) {
                this.UpdateMiniCart()
            }
        }
    },
    mounted() {
        const parent = this.$el.parentElement
        this.miniCartCounter = document.querySelectorAll('.cart-count')
        this.miniCartIcon = parent

        if (typeof parent.dataset.minicartIgnoreUpdate !== 'undefined') {
            this.GetCart()
        }

        this.registerMiniCart()

        EventBus.$on(CART_UPDATED, (data) => this.UpdateMiniCart(data))
    },
    methods: {
        toggleMiniCart(state) {
            this.miniCartVisible = state
        },
        GetCart() {
            return axios
                .post(BASE_URL + 'ajaxcart/index/getCart')
                .then((response) => {
                    this.UpdateMiniCart(response.data.cart)
                    return response
                })
        },
        UpdateMiniCart(data) {
            if (typeof data !== 'undefined') {
                localStorage.setItem('cart', JSON.stringify(data))
            }
            const cartData = data
                ? data
                : JSON.parse(localStorage.getItem('cart'))
            this.miniCart = cartData
            Array.from(this.miniCartCounter).forEach(
                (el) => (el.innerText = cartData.count || '0')
            )
        },
        registerMiniCart() {
            this.miniCartIcon.addEventListener('mouseenter', () => {
                if (window.innerWidth > 768) {
                    this.toggleMiniCart(true)
                    this.$nextTick(() => icons(this.$el))
                }
            })
            this.miniCartIcon.addEventListener('mouseleave', () =>
                this.toggleMiniCart(false)
            )
        },
        redirectToCart() {
            location.href = BASE_URL + 'checkout/cart'
        }
    }
}
</script>

<style lang="scss">
$b: &; // set context

.mini-cart {
    padding-top: 25px;
    position: absolute;
    z-index: $zindex-navbar-fixed + 1;
    right: 0;
    top: 100%;

    @media #{$media-xs} {
        display: none;
    }

    &__container {
        width: 400px;
        background-color: #fff;
        color: $gray-dark;
        border-top: 5px solid palette(green);
        box-shadow: 0 0 20px rgba(25, 25, 25, 0.1);
        text-align: left;
        position: relative;

        @include n-theme() {
            border-top-color: theme(color-primary);
        }

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid palette(green);
            position: absolute;
            top: -13px;
            right: 13px;

            @include n-theme() {
                border-bottom-color: theme(color-primary);
            }
        }
    }

    &__title {
        font-weight: 600;
        font-size: 15px;
        border-bottom: none;
        padding: 20px 20px 0;
        display: flex;
        align-items: flex-end;

        strong {
            font-weight: 700;
        }

        [data-icon] {
            margin-right: 10px;
        }
    }

    &__totals {
        background-color: $gray-light;
        padding: 16px 20px;
        text-align: right;
        font-weight: 700;
        line-height: 1.4;

        span {
            font-weight: 400;
            font-size: 24px;
            line-height: 1.2;
            color: $blue-dianne;
        }
    }

    &__actions {
        button {
            width: 100%;
            padding: 17px 10px;
            font-size: 15px;
            color: #fff;

            [data-icon] {
                margin-right: 15px;
            }
        }
    }

    &__empty {
        padding: 20px;
        margin: 0;
    }

    &__item {
        border-bottom: 1px solid #afafaf;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 30px 20px;
        width: 100%;

        &--inactive {
            opacity: 0.3;
        }

        &:last-child {
            border-bottom: 0;
        }

        &-container {
            display: flex;
            align-items: center;
            white-space: normal;
        }

        &-img {
            flex-shrink: 0;
            margin: 0 10px;
        }

        &-name {
            font-weight: 700;
            font-size: 13px;
            line-height: 20px;
            width: 127px;
            padding-left: 10px;
            margin: 0;
        }

        &-qty {
            width: 24.5%;
            text-align: center;
            font-size: 17px;
            font-weight: 600;
        }

        &-price {
            display: flex;
            flex-direction: column;
            width: 66px;
            align-items: flex-end;
            font-size: 18px;
            font-weight: 400;
            color: $blue-dianne;
        }

        &-remove {
            margin-bottom: 3px;

            .icon {
                color: $gray-darker;
                margin-bottom: 5px;
            }
        }

        &-unavailable_msg {
            min-width: 100%;
            display: block;
            text-align: center;
            padding: 5px 10px;
            font-size: 12px;
            background: $red;
            color: #fff;
        }

        &.is-unavailable {
            position: relative;
            border: 2px solid $red;

            .price {
                color: $red;
            }

            & + #{$b}__item {
                border-top: 1px solid $gray-lighter;
            }
        }
    }
}
</style>
